import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import { useParams, Link } from "react-router-dom"
import axios from "axios"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
} from "reactstrap"

function BannerEdit() {
  const params = useParams()
  const [bannerinfo, setBannerinfo] = useState([])
  const [banner, setBanner] = useState({
    title: "",
    description: "",
    type: "",
    sort: "",
    bannerImage: "",
  })
  let name, value
  const handleInputs = e => {
    console.log(e)
    name = e.target.name
    value = e.target.value
    setBanner({ ...banner, [name]: value })
  }
  //preview of bannerdata
  const getbannerinfo = async id => {
    const res = await fetch(
      process.env.REACT_APP_URL + `/api/banner/bannerdata/${params.id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
    const data = await res.json([])
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setBannerinfo(data)
      setBanner({
        ...banner,
        title: data.title,
        description: data.description,
        type: data.type,
        sort: data.sort,
        bannerImage: data.bannerImage,
      })
    }
  }
  const PostData = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("title", banner.title)
    formData.append("description", banner.description)
    formData.append("type", banner.type)
    formData.append("sort", banner.sort)
    formData.append("bannerImage", banner.bannerImage)
    axios
      .patch(
        process.env.REACT_APP_URL + `/api/banner/editbanner/${params.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(res => {
        console.log(res)
        if (res.status === 422 || !res) {
          window.alert("error")
        } else {
          alert("successfully Updated")
          // window.location.reload(false);
        }
      })
  }
  const onFileChange = e => {
    console.log(e)
    let name = e.target.name
    setBanner({ ...banner, [name]: e.target.files })
  }
  useEffect(() => {
    getbannerinfo()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="page-title-box">
          <h4
            className="font-size-18 text-dark"
            style={{ padding: "0px 15px" }}
          >
            Add Banner
          </h4>
        </div>
        <Container fluid={true}>
          {" "}
          <Row>
            <Col md="8"> </Col>
            <Col md="4">
              {" "}
              <div className="float-end d-none d-md-block mb-2">
                <Link
                  to={"/banner"}
                  color="primary"
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-keyboard-backspace me-2"></i> Back
                </Link>
              </div>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ padding: "30px 100px" }}>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Title
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={banner.title}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Sort
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          name="sort"
                          value={banner.sort}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Description
                      </label>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          className="form-control "
                          rows={8}
                          name="description"
                          value={banner.description}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Banner Type
                      </label>
                      <div className="mb-3">
                        <select
                          name="type"
                          value={banner.type}
                          onChange={handleInputs}
                          className="form-control "
                        >
                          <option>Choose banner type</option>
                          <option value="twin">Twin banner</option>
                          <option value="main">Main banner</option>
                          <option value="sub">Sub banner</option>
                          <option value="side">Side banner</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Image
                      </label>
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control "
                          id="bannerImage"
                          name="bannerImage"
                          onChange={onFileChange}
                          multiple
                          accept="image/*"
                        />
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-dark mb-3"
                          onClick={PostData}
                        >
                          Submit
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BannerEdit
