import PropTypes from "prop-types"
import React from "react"
import { Routes, Route } from "react-router-dom"
import { connect } from "react-redux"
import Cookies from "js-cookie"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"

// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import ToastContainer from "components/ToastContainer"

// Import scss
import "./assets/scss/theme.scss"

import Login from "pages/Authentication/Login"

const App = () => {
  let token = Cookies.get("value")
  console.log("token : ", token)
  if (token) {
    return (
      <React.Fragment>
        <ToastContainer />
        <Routes>
          <Route>
            {authRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>

          <Route>
            {userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<Authmiddleware>{route.component}</Authmiddleware>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>
        </Routes>
      </React.Fragment>
    )
  } else {
    return <Login />
  }
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
