import React, { createContext, useState, useEffect } from "react"

export const ShopContext = createContext(null)

const ShopContextProvider = props => {
  const [product, setProduct] = useState()
  const [order, setOrder] = useState()
  const [customer, setCustomer] = useState()
  const [contact, setContact] = useState()

  // total product count
  const getProductdata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/kaleproduct/countproduct",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setProduct(data)
    }
  }
  // total order count
  const getOrderdata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/orders/countorder",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setOrder(data)
    }
  }

  //  total customer
  const getCustomerdata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/auth/countcustomer",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setCustomer(data)
    }
  }

  // total contact count
  const getContactdata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/contactus/countcontact",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setContact(data)
    }
  }

  useEffect(() => {
    getProductdata()
    getOrderdata()
    getCustomerdata()
    getContactdata()
  }, [])

  const contextValue = {
    product,
    order,
    customer,
    contact,
  }

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  )
}

export default ShopContextProvider
