import React, { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import moment from "moment-timezone"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Table,
} from "reactstrap"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

import "./Tables/datatables.scss"

function OrderDetails() {
  const params = useParams()

  const [orderDetails, setOrderDetails] = useState({})
  const [orderItems, setOrderItems] = useState([])

  const getOrderDetails = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + `/api/orders/vieworder/${params.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setOrderDetails(data)
      setOrderItems(data.items)
      console.log("get order details")
    }
  }

  useEffect(() => {
    getOrderDetails()
  }, [])

  if (!orderDetails) {
    return <div>Loading...</div>
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <h4 className="font-size-18"> Order Summary</h4>
          </div>

          <Container fluid={true}>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={8}>
                        <div className="table-responsive mb-5">
                          <Table className="table table-bordered mb-0">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{"Date (Order Placed)"}</td>
                                <td>
                                  {moment(orderDetails?.createdAt).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>{"Order Id"}</td>
                                <td>{orderDetails?.order_Id}</td>
                              </tr>{" "}
                              <tr>
                                <td>{"Customer Name"}</td>
                                <td>{orderDetails?.name}</td>
                              </tr>{" "}
                              <tr>
                                <td>{"Contact Number"}</td>
                                <td>{orderDetails?.number}</td>
                              </tr>{" "}
                              <tr>
                                <td>{"Customer Address"}</td>
                                <td>
                                  {orderDetails?.address} ,{" "}
                                  {orderDetails?.state} ,{" "}
                                  {orderDetails?.zipcode}
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>{"CUstomer Address Landmark"}</td>
                                <td>{orderDetails?.landmark}</td>
                              </tr>{" "}
                              <tr>
                                <td>{"Payment Mode"}</td>
                                <td>{orderDetails?.paymentmode}</td>
                              </tr>{" "}
                              <tr>
                                <td>{"Amount"}</td>
                                <td>{orderDetails?.amount}</td>
                              </tr>{" "}
                              <tr>
                                <td>{"Transaction Id"}</td>
                                <td>{orderDetails?.transactionId}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={4}>
                        {" "}
                        <div className="table-responsive  mb-5">
                          <Table className="table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Product Name</th>
                                <th scope="col">Quantity</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderItems?.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.productName}</td>
                                  <td>{item.quantity}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderDetails
