import PropTypes from "prop-types"
import React, { useState, useContext } from "react"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"

import Icon from "@mdi/react"
import { mdiCart, mdiArchive, mdiAccountStar, mdiEmail } from "@mdi/js"

import { ShopContext } from "../../Context/shop-context"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  const { product, order, customer, contact } = useContext(ShopContext)

  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center ">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active mb-5">
                    Welcome to KalesAgro admin Dashboard
                  </li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Dropdown isOpen={menu} toggle={toggle}>
                    <DropdownToggle
                      color="primary"
                      className="btn btn-dark dropdown-toggle waves-effect waves-light"
                    >
                      <i className="mdi mdi-cog me-2"></i> Settings
                    </DropdownToggle>
                  </Dropdown>
                </div>
              </Col>
            </Row>

            <Row className="mt-5 mb-5">
              <Col xl={3} md={2}></Col>
              <Col xl={3} md={4}>
                <Card className="mini-stat text-white">
                  <CardBody style={{ backgroundColor: "#333547" }}>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4 ">
                        <Icon path={mdiCart} size={2} />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Orders
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {order}
                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>
                      <div className="mini-stat-label bg-success">
                        <p className="mb-0">+ 12%</p>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div className="float-end">
                        <Link to="#" className="text-white-50">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>
                      <p className="text-white-50 mb-0 mt-1">
                        Total orders placed
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>{" "}
              <Col xl={3} md={4}>
                <Card className="mini-stat  text-white">
                  <CardBody style={{ backgroundColor: "#333547" }}>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <Icon path={mdiArchive} size={2} />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Products
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {product}
                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>
                      <div className="mini-stat-label bg-success">
                        <p className="mb-0">12%</p>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div className="float-end">
                        <Link to="#" className="text-white-50">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>

                      <p className="text-white-50 mb-0 mt-1">Total products</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={2}></Col>
            </Row>

            <Row>
              <Col xl={3} md={2}></Col>
              <Col xl={3} md={4}>
                <Card className="mini-stat text-white">
                  <CardBody style={{ backgroundColor: "#333547" }}>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <Icon path={mdiAccountStar} size={2} />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Customers
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {customer}
                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>
                      <div className="mini-stat-label bg-success">
                        <p className="mb-0">12%</p>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div className="float-end">
                        <Link to="#" className="text-white-50">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>

                      <p className="text-white-50 mb-0 mt-1">
                        Total registered users
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} md={4}>
                <Card className="mini-stat  text-white">
                  <CardBody style={{ backgroundColor: "#333547" }}>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <Icon path={mdiEmail} size={2} />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        Enquiries
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {contact}

                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>
                      <div className="mini-stat-label bg-success">
                        <p className="mb-0">12%</p>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div className="float-end">
                        <Link to="#" className="text-white-50">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>
                      <p className="text-white-50 mb-0 mt-1">
                        Total mail enquiries
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={2}></Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
