import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { toast } from "react-toastify"

import ToastContainer from "components/ToastContainer"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
} from "reactstrap"

// import images
import logo from "../../assets/afpImages/logo-black.png"

const Register = () => {
  const [registerAdmin, setRegisterAdmin] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    cpassword: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setRegisterAdmin({ ...registerAdmin, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const { name, email, number, password, cpassword } = registerAdmin
      const res = await fetch(
        process.env.REACT_APP_URL + "/api/auth/admin-register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            number,
            password,
            cpassword,
          }),
        }
      )

      if (res.status === 201) {
        toast.success("User registered successfully")
        setTimeout(() => {
          window.location.replace("/")
        }, 3000)
      } else {
        toast.error("User registration failed")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <Card className="overflow-hidden">
                <div className="bg-secondary">
                  <div className="text-dark text-center p-4">
                    <Link to="/">
                      <img src={logo} height="50" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="name">
                          Name
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          value={registerAdmin.name}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="email">
                          Email
                        </Label>
                        <Input
                          type="text"
                          name="email"
                          value={registerAdmin.email}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="number">
                          Phone Number
                        </Label>
                        <Input
                          type="number"
                          name="number"
                          value={registerAdmin.number}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password">
                          Password
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          value={registerAdmin.password}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="cpassword">
                          Conform Password
                        </Label>
                        <Input
                          type="password"
                          name="cpassword"
                          value={registerAdmin.cpassword}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="mt-3 mb-3 row">
                        <div className="col-md-12 text-center">
                          <button
                            className="btn btn-dark w-md waves-effect waves-light"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
