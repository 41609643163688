import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
} from "reactstrap"

function CategoryCreate() {
  const [cat, setCat] = useState([])
  const [category, setCategory] = useState({
    title: "",
    sort: "",
    slug: "",
    parentid: "",
    icon: "",
  })
  let name, value
  const handleInputs = e => {
    console.log(e)
    name = e.target.name
    value = e.target.value
    setCategory({ ...category, [name]: value })
  }
  //to get category names
  const getCategorydata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/categorys/categorydata",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      toast.error("Error in retrieving category data")
    } else {
      setCat(data)
      console.log("get category data")
    }
  }
  //TO post category data
  const PostData = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("title", category.title)
    formData.append("sort", category.sort)
    formData.append("slug", category.slug)
    formData.append("parentid", category.parentid)
    formData.append("icon", category.icon)
    axios
      .post(
        process.env.REACT_APP_URL + "/api/categorys/add-category",
        formData,
        {}
      )
      .then(res => {
        console.log(res)
        if (res.status === 422 || !res) {
          toast.error("Error in adding category")
        } else {
          toast.success("Category added successfully")
          setTimeout(() => {
            window.location.reload(true)
          }, 3000)
        }
      })
  }

  const onFileChange = e => {
    console.log(e)
    let name = e.target.name
    setCategory({ ...category, [name]: e.target.files[0] })
  }
  useEffect(() => {
    getCategorydata()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="page-title-box">
          <h4
            className="font-size-18 text-dark"
            style={{ padding: "0px 15px" }}
          >
            Add Product Category
          </h4>
        </div>
        <Container fluid={true}>
          <Row>
            <Col md="8"> </Col>
            <Col md="4">
              {" "}
              <div className="float-end d-none d-md-block mb-2">
                <Link
                  to={"/category"}
                  color="primary"
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-keyboard-backspace me-2"></i> Back
                </Link>
              </div>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ padding: "30px 100px" }}>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Title
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={category.title}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Parent Category
                      </label>
                      <div className="mb-3">
                        <select
                          name="parentid"
                          value={category.parentid}
                          onChange={handleInputs}
                          className="form-control"
                          placeholder=" "
                        >
                          <option value="">choose parent catgeory</option>
                          {cat.map(element => {
                            return (
                              <>
                                <option value={element._id}>
                                  {element.title}
                                </option>
                              </>
                            )
                          })}
                        </select>
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Sort
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="Sort"
                          name="sort"
                          value={category.sort}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Slug
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Slug"
                          name="slug"
                          value={category.slug}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Choose icon:
                      </label>
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control "
                          name="icon"
                          onChange={onFileChange}
                          accept="image/*"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-dark mb-3"
                          onClick={PostData}
                        >
                          Submit
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CategoryCreate
