// import React, { useState, useEffect } from "react"
// import { useNavigate, useParams, Link } from "react-router-dom"
// import moment from "moment-timezone"
// import { MDBDataTable } from "mdbreact"
// import {
//   Row,
//   Col,
//   Card,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   Table,
// } from "reactstrap"
// import { ToastContainer, toast } from "react-toastify"
// import Swal from "sweetalert2"

// import "./Tables/datatables.scss"

// function Contact() {
//   const [tableData, setTableData] = useState([])

//   const getEnquirydata = async e => {
//     const res = await fetch(
//       process.env.REACT_APP_URL + "/api/contactus/enquirydetails",
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     const data = await res.json()
//     console.log(data)
//     if (res.status === 201) {
//       setTableData(data)
//       console.log("get enquiry data")
//     } else {
//       toast.error("error in fetching enquiry details")
//       console.log("error in fetching enquiry details")
//     }
//   }
//   useEffect(() => {
//     getEnquirydata()
//   }, [])

//   const data = {
//     columns: [
//       {
//         label: "name",
//         field: "name",
//         sort: "asc",
//       },
//       {
//         label: "email",
//         field: "email",
//         sort: "asc",
//       },
//       {
//         label: "Subject",
//         field: "subject",
//         sort: "asc",
//       },
//       {
//         label: "Message",
//         field: "message",
//       },
//     ],
//     rows: tableData.map(con => ({
//       name: con?.name,
//       email: con?.email,
//       subject: con?.subject,
//       message: con?.message,
//     })),
//   }
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <div className="container-fluid">
//           <div className="page-title-box">
//             <h4 className="font-size-18"> Enquiry List</h4>
//           </div>
//           <Row>
//             <Col className="col-12">
//               <Card>
//                 <CardBody>
//                   {/* <MDBDataTable responsive bordered data={data} hover /> */}
//                   <div className="table-responsive  mb-5">
//                     <Table className="table table-bordered mb-0">
//                       <thead>
//                         <tr>
//                           <th scope="col">Name</th>
//                           <th scope="col">Email</th>{" "}
//                           <th scope="col">Subject</th>
//                           <th scope="col">Message</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {tableData?.map((con, index) => (
//                           <tr key={index}>
//                             <td>{con?.name}</td>
//                             <td>{con?.email}</td>
//                             <td>{con?.subject}</td>
//                             <td>{con?.message}</td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </React.Fragment>
//   )
// }

// export default Contact

import React, { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import moment from "moment-timezone"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

import "./Tables/datatables.scss"

function Contact() {
  const [contactList, setContactList] = useState([])

  const getContactList = async () => {
    const res = await fetch(
      process.env.REACT_APP_URL + `/api/contact/contacts`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()
    console.log(data.contacts)
    if (res.status === 404 || !data) {
      toast.error("error in retrieving messages")
    } else {
      // toast.success("successfully retrieved messages")
      setContactList(data.contacts)
      console.log("contacts")
    }
  }

  useEffect(() => {
    getContactList()
  }, [])

  const handleDelete = async id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_URL}/api/contact/delete-contact/${id}`,
            {
              method: "DELETE",
            }
          )
          if (res.status === 201) {
            toast.success("contact deleted successfully", {
              position: "top-center",
            })
            getContactList()
            console.log("contact deleted successfully")
          } else {
            console.log("Error deleting contact")
          }
        } catch (error) {
          console.error("Error deleting contact:", error)

          toast.error("Error occurred while deleting the contact.", {
            position: "top-center",
            autoClose: false,
          })
        }
      }
    })
  }

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        // width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        // width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        // width: 200,
      },
      {
        label: "Subject",
        field: "subject",
        sort: "asc",
        // width: 100,
      },
      {
        label: "Comments",
        field: "comments",
        sort: "asc",
        // width: 100,
      },
      {
        label: "Delete",
        field: "action",
      },
    ],
    rows: contactList.map(contact => ({
      name: contact?.name,
      email: contact?.email,
      phone: contact?.phone,
      subject: contact?.subject,
      comments: contact?.comments,
      action: (
        <div className="d-flex">
          <Link>
            <i
              className="fas fa-trash text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(contact?._id)}
            />
          </Link>
        </div>
      ),
    })),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <h4 className="font-size-18">Message List</h4>
          </div>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} hover />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Contact
