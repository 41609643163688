import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
} from "reactstrap"

function ProductCreate() {
  const [cat, setCat] = useState([])
  const [previewImage, setPreviewImage] = useState("")
  const [offerprice, setOfferprice] = useState(0)
  const [product, setProduct] = useState({
    title: "",
    category: [],
    desc: "",
    papertype: "",
    coating: "",
    color: "",
    quantities: "",
    sizes: "",
    finishing: "",
    filetype: "",
    price1: "",
    price2: "",
    price3: "",
    price4: "",
    image: [],
    imagePreviewUrls: [],
  })
  let name, value
  const handleInputs = e => {
    console.log(e)
    name = e.target.name
    value = e.target.value
    setProduct({ ...product, [name]: value })
  }
  const PostData = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("title", product.title)
    formData.append("category", product.category)
    formData.append("desc", product.desc)
    formData.append("papertype", product.papertype)
    formData.append("coating", product.coating)
    formData.append("color", product.color)
    formData.append("quantities", product.quantities)
    formData.append("sizes", product.sizes)

    formData.append("finishing", product.finishing)
    formData.append("filetype", product.filetype)
    formData.append("price1", product.price1)
    formData.append("price2", product.price2)
    formData.append("price3", product.price3)
    formData.append("price4", product.price4)

    for (let i = 0; i < product.image.length; i++) {
      formData.append("image", product.image[i])
    }

    axios
      .post(
        process.env.REACT_APP_URL + "/api/products/add-product",
        formData,
        {}
      )
      .then(res => {
        console.log(res)
        if (res.status === 422 || !res) {
          toast.error("Error in adding product")
        } else {
          toast.success("Product added successfully")
          setTimeout(() => {
            window.location.reload(true)
          }, 3000)
        }
      })
  }

  //list of category
  const getCategorydata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/categorys/categorydata",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      toast.error("Error in retrieving category data")
    } else {
      setCat(data)
      console.log("get category data")
    }
  }

  const onFileChange = e => {
    let name = e.target.name
    let files = e.target.files

    let updatedImages = [...product.image]
    let updatedPreviewUrls = [...product.imagePreviewUrls]

    for (let i = 0; i < files.length; i++) {
      let file = new File([files[i]], files[i].name, { type: files[i].type })
      let reader = new FileReader()
      reader.onloadend = () => {
        updatedImages.push(file)
        updatedPreviewUrls.push(reader.result)
        if (updatedImages.length === files.length) {
          setProduct({
            ...product,
            [name]: updatedImages,
            imagePreviewUrls: updatedPreviewUrls,
          })
        }
      }
      reader.readAsDataURL(files[i])
    }
  }

  useEffect(() => {
    getCategorydata()
    const { price, percentage } = product
    const per = (price * percentage) / 100
    let actualamnt = price - per
    setOfferprice(actualamnt)
  }, [product])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="page-title-box">
          <h4
            className="font-size-18 text-dark"
            style={{ padding: "0px 15px" }}
          >
            Add Product
          </h4>
        </div>
        <Container fluid={true}>
          <Row>
            <Col md="8"> </Col>
            <Col md="4">
              {" "}
              <div className="float-end d-none d-md-block mb-2">
                <Link
                  to={"/product"}
                  color="primary"
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-keyboard-backspace me-2"></i> Back
                </Link>
              </div>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ padding: "30px 100px" }}>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Title
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={product.title}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Category
                      </label>
                      <div className="mb-3">
                        <select
                          name="category"
                          value={product.category}
                          onChange={handleInputs}
                          className="form-control "
                          required
                        >
                          <option value="" disabled>
                            choose category
                          </option>
                          {cat.map(element => {
                            return (
                              <>
                                <option value={element._id}>
                                  {element.title}
                                </option>
                              </>
                            )
                          })}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Description
                      </label>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          className="form-control "
                          rows={4}
                          name="desc"
                          value={product.desc}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Paper Type
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="papertype"
                          name="papertype"
                          value={product.papertype}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Coating
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="coating"
                          name="coating"
                          value={product.coating}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Color
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="color"
                          name="color"
                          value={product.color}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Quantities
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="quantities"
                          name="quantities"
                          value={product.quantities}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Sizes
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="sizes"
                          name="sizes"
                          value={product.sizes}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Finishing
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="finishing"
                          name="finishing"
                          value={product.finishing}
                          onChange={handleInputs}
                        />
                      </div>
                    </Row>{" "}
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        File type
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="filetype"
                          name="filetype"
                          value={product.filetype}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Price for 50
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="price1"
                          name="price1"
                          value={product.price1}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Price for 250
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="price2"
                          name="price2"
                          value={product.price2}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Price for 500
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="price3"
                          name="price3"
                          value={product.price3}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Price for 1000
                      </label>
                      <div className="mb-3">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="price4"
                          name="price4"
                          value={product.price4}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="mb-1 col-form-label text-dark">
                        Choose Images:
                      </label>
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control "
                          name="image"
                          onChange={onFileChange}
                          multiple
                          accept="image/*"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      {" "}
                      <div className="image-preview">
                        {product.imagePreviewUrls &&
                          product.imagePreviewUrls.map(url => (
                            <img
                              src={url}
                              alt="preview"
                              key={url}
                              height={"100px"}
                              width="150px"
                              style={{ margin: "10px" }}
                            />
                          ))}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-dark mb-3"
                          onClick={PostData}
                        >
                          Submit
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductCreate
