import React, { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import moment from "moment-timezone"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

import "./Tables/datatables.scss"

function Order() {
  const [tableData, setTableData] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const getOrderdata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/orders/orderlist",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setTableData(data)
      const totalAmount =
        data && data?.length
          ? data?.reduce((a, b) => a + (b?.amount || 0), 0)
          : 0
      setTotalAmount(totalAmount)
      console.log("get order data")
    }
  }

  useEffect(() => {
    getOrderdata()
  }, [])

  const data = {
    columns: [
      {
        label: "Date",
        field: "createdAt",
      },
      {
        label: "Order-Id",
        field: "order_Id",
        sort: "asc",
      },

      {
        label: "Name",
        field: "name",
        sort: "asc",
      },
      {
        label: "Contact",
        field: "number",
        sort: "asc",
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
      },
      {
        label: "State",
        field: "state",
      },
      {
        label: "Pincode",
        field: "zipcode",
      },
      {
        label: "Amount",
        field: "amount",
      },
      {
        label: "TransactionId",
        field: "transactionId",
      },
      {
        label: "View",
        field: "view",
        width: 150,
      },
    ],
    rows: tableData.map(order => ({
      createdAt: order?.createdAt
        ? moment(order?.createdAt).format("MM/DD/YYYY")
        : "",
      order_Id: order?.order_Id,
      name: order?.name,
      number: order?.number,
      address: order?.address,
      state: order?.state,
      zipcode: order?.zipcode,
      amount: order?.amount,
      transactionId: order?.transactionId,
      view: (
        <div className="d-flex">
          <Link to={"/vieworder/" + order?._id} style={{ marginRight: "10px" }}>
            <i
              className="fas fa-edit text-success"
              style={{ marginRight: "10px", cursor: "pointer" }}
            ></i>
          </Link>
        </div>
      ),
    })),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <h4 className="font-size-18">Order List</h4>
          </div>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} hover />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Order
