import React from "react"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "pages/Authentication/Register"

//Utility
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Banner from "pages/Banner"
import BannerCreate from "pages/BannerCreate"
import BannerEdit from "pages/BannerEdit"
import Newsletter from "pages/Newsletter"
import Blog from "pages/Blog"
import Product from "pages/Product"
import ProductCreate from "pages/ProductCreate"
import ProductEdit from "pages/ProductEdit"
import Category from "pages/Category"
import CategoryCreate from "pages/CategoryCreate"
import CategoryEdit from "pages/CategoryEdit"
import Order from "pages/Order"
import OrderDetails from "pages/OrderDetails"
import Contact from "pages/Contact"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
  { path: "/banner", component: <Banner /> },
  { path: "/addbanner", component: <BannerCreate /> },
  { path: "/editbanner/:id", component: <BannerEdit /> },
  { path: "/newsletter", component: <Newsletter /> },
  { path: "/blog", component: <Blog /> },
  { path: "/product", component: <Product /> },
  { path: "/addproduct", component: <ProductCreate /> },
  { path: "/editproduct/:id", component: <ProductEdit /> },
  { path: "/category", component: <Category /> },
  { path: "/addcategory", component: <CategoryCreate /> },
  { path: "/editcategory/:id", component: <CategoryEdit /> },
  { path: "/order", component: <Order /> },
  { path: "/vieworder/:id", component: <OrderDetails /> },
  { path: "/contact", component: <Contact /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/admin-register", component: <Register /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }
