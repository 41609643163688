import React, { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import moment from "moment-timezone"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

import "./Tables/datatables.scss"

function Product() {
  const params = useParams()
  const [tableData, setTableData] = useState([])
  const [cat, setCat] = useState([])

  const getProductdata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/products/productdata",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      toast.error("Error in retrieving product data")
    } else {
      setTableData(data)
      console.log("get product list")
    }
  }

  //list of category
  const getCategorydata = async e => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/categorys/categorydata",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      toast.error("Error in retrieving category data")
    } else {
      setCat(data)
      console.log("get category data")
    }
  }

  useEffect(() => {
    getProductdata()
    getCategorydata()
  }, [])

  const handleDelete = async id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_URL}/api/products/deleteproduct/${id}`,
            {
              method: "DELETE",
            }
          )
          if (res.status === 201) {
            toast.success("Product deleted successfully", {
              position: "top-center",
            })
            getProductdata()
            console.log("Product deleted successfully")
          } else {
            toast.error("Error deleting product")
            console.log("Error deleting product")
          }
        } catch (error) {
          console.error("Error deleting product:", error)
          toast.error("Error occurred while deleting the product.", {
            position: "top-center",
            autoClose: false,
          })
        }
      }
    })
  }

  const generateImageElement = imageUrl => {
    return (
      <img
        src={`${process.env.REACT_APP_URL}${imageUrl}`}
        alt="Product"
        style={{ width: "50px", height: "50px" }}
      />
    )
  }

  const data = {
    columns: [
      {
        label: "Image",
        field: "image",
      },
      ,
      {
        label: "Title",
        field: "title",
        sort: "asc",
      },
      {
        label: "Description",
        field: "desc",
        sort: "asc",
        width: 500,
      },
      // {
      //   label: "Category",
      //   field: "category",
      //   sort: "asc",
      // },
      {
        label: "Paper Type",
        field: "papertype",
        sort: "asc",
      },
      {
        label: "Coating",
        field: "coating",
        sort: "asc",
      },
      {
        label: "Quantities",
        field: "quantities",
        sort: "asc",
      },
      {
        label: "Sizes",
        field: "sizes",
        sort: "asc",
      },
      {
        label: "Finishing",
        field: "finishing",
        sort: "asc",
      },
      {
        label: "File Type",
        field: "filetype",
        sort: "asc",
      },
      {
        label: "Price - 50pcs",
        field: "price1",
        sort: "asc",
      },
      {
        label: "Price - 250pcs",
        field: "price2",
        sort: "asc",
      },
      {
        label: "Price - 500pcs",
        field: "price3",
        sort: "asc",
      },
      {
        label: "Price - 1000pcs",
        field: "price4",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: tableData.map(product => ({
      image: generateImageElement(product?.image[0]),
      title: product?.title,
      desc: product?.desc,
      // category: product?.category,
      papertype: product?.papertype,
      coating: product?.coating,
      quantities: product?.quantities,
      sizes: product?.sizes,
      finishing: product?.finishing,
      filetype: product?.filetype,
      price1: product?.price1,
      price2: product?.price2,
      price3: product?.price3,
      price4: product?.price4,

      action: (
        <div className="d-flex">
          <Link
            to={"/editproduct/" + product?._id}
            style={{ marginRight: "10px" }}
          >
            <i
              className="fas fa-edit text-success"
              style={{ marginRight: "10px", cursor: "pointer" }}
            ></i>
          </Link>
          <Link>
            <i
              className="fas fa-trash text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(product?._id)}
            />
          </Link>
        </div>
      ),
    })),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <h4 className="font-size-18">Product List</h4>
          </div>
          <Row>
            <Col md="4">
              {" "}
              <div className="float-start d-none d-md-block mb-2">
                <Link
                  to={"/addproduct"}
                  color="primary"
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-plus me-2"></i> Add product
                </Link>
              </div>
            </Col>
            <Col md="8"></Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} hover />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Product
