import React, { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import moment from "moment-timezone"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

import "./Tables/datatables.scss"

function Banner() {
  const [tableData, setTableData] = useState([])

  const getBannerdata = async () => {
    const res = await fetch(
      process.env.REACT_APP_URL + "/api/banner/bannerlist",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()
    console.log(data)
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      setTableData(data)
      console.log("get banner data")
    }
  }
  useEffect(() => {
    getBannerdata()
  }, [])

  const handleDelete = async id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_URL}/api/banner/deletebanner/${id}`,
            {
              method: "DELETE",
            }
          )
          if (res.status === 201) {
            toast.success("banner deleted successfully", {
              position: "top-center",
            })
            getBannerdata()
            console.log("banner deleted successfully")
          } else {
            console.log("Error deleting banner")
            toast.error("Error deleting banner")
          }
        } catch (error) {
          console.error("Error deleting banner:", error)
          toast.error("Error occurred while deleting the banner.", {
            position: "top-center",
            autoClose: false,
          })
        }
      }
    })
  }

  const generateImageElement = imageUrl => {
    return (
      <img
        src={`${process.env.REACT_APP_URL}${imageUrl}`}
        alt="Banner"
        style={{ width: "50px", height: "50px" }}
      />
    )
  }

  const data = {
    columns: [
      {
        label: "Image",
        field: "bannerImage",
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
      },
      {
        label: "Sort",
        field: "sort",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: tableData.map(banner => ({
      bannerImage: generateImageElement(banner?.bannerImage),
      title: banner?.title,
      description: banner?.description,
      type: banner?.type,
      sort: banner?.sort,
      action: (
        <div className="d-flex">
          <Link
            to={"/editbanner/" + banner?._id}
            style={{ marginRight: "10px" }}
          >
            <i
              className="fas fa-edit text-success"
              style={{ marginRight: "10px", cursor: "pointer" }}
            ></i>
          </Link>
          <Link>
            <i
              className="fas fa-trash text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(banner?._id)}
            />
          </Link>
        </div>
      ),
    })),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <h4 className="font-size-28">Banner List</h4>
          </div>
          <Row>
            <Col md="4">
              {" "}
              <div className="float-start d-none d-md-block mb-2">
                <Link
                  to={"/addbanner"}
                  className="btn btn-dark dropdown-toggle waves-effect waves-light"
                >
                  <i className="mdi mdi-plus me-2"></i> Add banner
                </Link>
              </div>
            </Col>
            <Col md="8"></Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} hover />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Banner
