import React from "react"
import { ToastContainer as ReactToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const ToastContainer = ({ position = "top-right", autoClose = 1800 }) => {
  return (
    <ReactToastContainer
      position={position}
      autoClose={autoClose}
      newestOnTop
      closeOnClick={false}
      draggable={false}
      pauseOnHover={false}
    />
  )
}

export default ToastContainer
